import API from "../utils/api";

export const ACTION_TYPES = {
    USER_CREATE: 'USER_CREATE',
    USER_UPDATE: 'USER_UPDATE',
    USER_DELETE: 'USER_DELETE',
    USER_FETCH: 'USER_FETCH',
    USER_FETCH_ALL: 'USER_FETCH_ALL',
    USER_PAGINATION: 'USER_PAGINATION',
    USER_CART_UPDATE: 'USER_CART_UPDATE',
    USER_FETCH_LOGIN: 'USER_FETCH_LOGIN',
    USER_FETCH_LOGIN_M: 'USER_FETCH_LOGIN_M',
    USER_ONLINE: 'USER_ONLINE'
}

export const fetchAll = () => dispatch => {
    API.user().fetchAll()
        .then(res => {
            dispatch({
                type: ACTION_TYPES.USER_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 5, name = "", email = "") => dispatch => {
    API.user().fetchPagination(page, Math.abs(limit), name, email)
        .then(res =>{
        // console.log('users111', res.data)
            dispatch({
                type: ACTION_TYPES.USER_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}


export const fetchById = (id, onSuccess) => dispatch => {
    API.user().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.USER_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const fetchCart = (id) => dispatch => {
    API.user().fetchById(id)
        .then(res =>{
            var count = {};
            res.data.cart.forEach(function(i) { count[i] = (count[i]||0) + 1;});
            let cartObj={
                cart:count,
                cartArray:res.data.cart
            }
            dispatch({
                type: ACTION_TYPES.USER_CART_UPDATE,
                payload: cartObj
               // payload: res.data.cart
            })
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.user().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.USER_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input) => dispatch => {

    API.user().update(id, input)
        .then(res =>{
       
            dispatch({
                type: ACTION_TYPES.USER_UPDATE,
                payload: res.data
            })
           // onSuccess()
        })
        .catch(err => console.log(err))
}

export const updateCart = (id, input, onSuccess) => dispatch => {

    API.user().update(id, input)
        .then(res =>{
            var count = {};
            res.data.cart.forEach(function(i) { count[i] = (count[i]||0) + 1;});
           let cartObj={
                cart:count,
                cartArray:res.data.cart
            }

            global.cartBadge =Object.keys(res.data.cart).length

            dispatch({
                type: ACTION_TYPES.USER_CART_UPDATE,
                payload: cartObj
               // payload: res.data.cart
            })
            onSuccess(cartObj)
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.user().delete(id)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.USER_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}