import React from "react";
import API from "../utils/api";
import jwt from "jwt-decode";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { decodeToken } from "react-jwt";
import store from "./../store/store";
let AuthContext;
const { Provider, Consumer } = (AuthContext = React.createContext());

class AuthProvider extends React.PureComponent {
  state = {
    token: null,
    authUser: null,
    errorMsg: null,
  };

  isAuthenticated = () => {
    const tken = this.state.token
      ? this.state.token
      : localStorage.getItem("token");
    if (!tken) return false;
debugger
    const decodedold = jwt(tken);
    let token = this.GetQueryStringParams("j_token");
    const decoded = decodeToken(token);
    let navParams = {};
    if (decoded?.current_user) {
      if (decoded.navigate_to_user.length > 0) {
        navParams.navigate_to_user = decoded.navigate_to_user;
        navParams.message = decoded.message;
        navParams.is_msg_transfer = decoded.is_msg_transfer;
      }
      localStorage.setItem("navParams", JSON.stringify(navParams));
    }
     

    if (Date.now() / 1000 > decodedold.exp - 5) {
      localStorage.clear();
      toast.error("Session has expired, please re-login");
      return false;
    }
   // window.history.pushState(null,"",'/');
    return true;
  };

  GetQueryStringParams = sParam => {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split("&");

    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split("=");
      if (sParameterName[0] == sParam) {
        return sParameterName[1];
      }
    }
  };
  getAuthUser = () => {
    let windowUrl = window.location.href;
    let token = this.GetQueryStringParams("j_token");
    const decoded = decodeToken(token);
    const usr = this.state.authUser
      ? this.state.authUser
      : localStorage.getItem("authUser");

    // decoded.current_user!==usr.user.id
    if (!usr) return null;
   // console.log("auth null", usr);
    try {
      const parsed = JSON.parse(usr);
    // console.log("auth121", parsed, decoded);
      if (decoded && decoded.current_user !== parsed.email) {
       // console.log("auth null", parsed);
        this.setState({
          token: null,
          authUser: null,
        });
        localStorage.removeItem("token");
        localStorage.removeItem("authUser");
        Cookies.remove("currentTenantnumber");
        //window.location.href = windowUrl;
        // window.location.replace(windowUrl);
        // window.reload()

        this.login(decoded.current_user, "%%Wp1i+RR@nO!930hdi3*#.d;cosj,!djpb7");
        //window.location.reload();
        setTimeout(() => window.location.reload(), 1500)

        return null;
      } else {
        return parsed;
      }
    } catch (err) {
      return usr;
    }
  };

  setLogin = data => {
    if (data) {
      localStorage.setItem("token", data.token);
      localStorage.setItem("authUser", JSON.stringify(data));
      this.setState({
        token: data.token,
        authUser: data.user,
      });
    } else {
      this.setState({
        token: null,
        authUser: null,
      });
      localStorage.removeItem("token");
      localStorage.removeItem("authUser");
    }
  };

  login = (email, password, history, setIsLoading, setErrorMessage, props) => {
    setIsLoading(true);
    this.setLogin(null);

    if (!!email && !!password) {
      API.auth()
        .login({
          username: email,
          password: password,
        })
        .then(res => {
         // console.log("res : ", res.data);

          if (res.status === 200 && res.data) {
            // if(res.data.role!=="admin"){
            //   setIsLoading(false);
            //   setErrorMessage("You are not an admin.")
            //   return false
            // }
            //console.log(res.data.role)
            res.data.role = "agent";
            this.setLogin(res.data);
            setIsLoading(false);
            setErrorMessage(null);

            let token = this.GetQueryStringParams("j_token");
            const decoded = decodeToken(token);
            let navParams = {};
            if (decoded.current_user === res.data.email) {
              if (decoded.navigate_to_user.length > 0) {
                navParams.navigate_to_user = decoded.navigate_to_user;
                navParams.message = decoded.message;
                navParams.is_msg_transfer = decoded.is_msg_transfer;
              }
            }
            if (res.data.role === "agent") {
              localStorage.setItem("navParams", JSON.stringify(navParams));

              history.replace("/",  { state: navParams });
              window.location.href = "/";
              history.push("/", { state: navParams });
            }
            if (res.data.role === "admin") {
              history.push("/admin/dashboard");
            }

            if (res.data.role === "superadmin") {
              //history.push('/admin/Tenantnumber')
              history.push("/admin/dashboard");
            }

            return true;
          } else {
            setIsLoading(false);
            setErrorMessage(res.data.message);
            return false;
          }
        })
        .catch(err => {
          setIsLoading(false);

          console.log(err);
          if (err.response) setErrorMessage(err.response.data.message);
          else {
            setErrorMessage(err);
          }

          return false;
        });
    } else {
      setIsLoading(false);
      setErrorMessage("email and password is empty");
      return false;
    }
  };

  register = (
    email,
    name,
    password,
    history,
    setIsLoading,
    setErrorMessage,
  ) => {
    setIsLoading(true);

    if (!!email && !!name && !!password) {
      API.auth()
        .register({ email, name, password })
        .then(res => {
          // console.log(res)
          setErrorMessage(null);
          setIsLoading(false);
          history.push("/admin/login");
          window.location.href = "/";
        })
        .catch(err => {
         // console.log(err.response.data);
          setErrorMessage(err.response.data.error);
          setIsLoading(false);
        });
    } else {
      setErrorMessage("All field is required");
      setIsLoading(false);
    }
  };

  logout = history => {
    this.setState({
      token: null,
      authUser: null,
    });
    localStorage.removeItem("token");
    localStorage.removeItem("authUser");
    Cookies.remove("currentTenantnumber");

    window.location.href = "/";
  };

  render() {
    return (
      <Provider
        value={{
          ...this.state,

          login: this.login,
          register: this.register,
          logout: this.logout,
          isAuthenticated: this.isAuthenticated,
          getAuthUser: this.getAuthUser,
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

export { AuthProvider, Consumer, AuthContext };
