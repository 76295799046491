import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { Virtuoso } from "react-virtuoso";
import moment from "moment";
import { toast } from "react-toastify";
import * as msgs from "./../../actions/msg";
import * as rooms from "./../../actions/room";
import * as users from "./../../actions/user";
import PreviewModal from "react-media-previewer";
import ContentEditable from "react-contenteditable";
import { connect } from "react-redux";
import wsbg from "./../../images/wsbg.jpg";
import { socket } from "../../context/socket";
import Button from "@material-ui/core/Button";
import { Tabs, Tab, Fade, TabPanel } from "@material-ui/core";
import {
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
  MenuOpenOutlined as MenuOpenOutlinedIcon,
  SendSharp,
  EmojiEmotionsOutlined,
  TextsmsOutlined,
  PagesRounded,
  FormatListBulleted,
  AttachFileOutlined,
  DoneAll,
  Done,
  Mic,
  StopRounded,
  MoreVert,
  ArrowDownward,
  KeyboardArrowDown,
  Close,
  FiberManualRecord,
  CheckBoxOutlineBlank,
  CheckOutlined,
  Check,
  Shortcut,
} from "@material-ui/icons";
import EmojiPicker from "emoji-picker-react";
import ReactHtmlParser from "react-html-parser";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { AuthContext } from "../../context/AuthContext";
import { store } from "../../store/store";
import { ACTION_TYPES } from "../../actions/msg";
import { ACTION_TYPES as ACTION_TYPES_ROOMS } from "./../../actions/room";
import TextareaAutosize from "react-textarea-autosize";
import API from "../../utils/api";
import { ReactMic } from "react-mic";
import { Checkbox, Tooltip } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Avatar } from "@material-ui/core";
import { TableVirtuoso } from "react-virtuoso";
import { debounce } from "lodash";
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

const Chatbox = props => {
  const virtuosoRef = useRef(null);
  const fileInputRef = useRef(null);
  const timerRef = useRef(null);
  const inputEl = useRef(null);
  const currRoomRef = useRef(null);
  const inputRef = useRef(null);
  const emojiRef = useRef(null);

  const { getAuthUser } = useContext(AuthContext);
  const authUser = getAuthUser();

  // State variables
  const [chatrooms, setChatrooms] = useState([]);

  const [chats, setChats] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingFinished, setLoadingFinished] = useState(false);
  const [firstItemIndex, setFirstItemIndex] = useState(1000);
  const [page, setPage] = useState(1);
  const [isSmiley, setIsSmiley] = useState(false);
  const [showMediaMenu, setShowMediaMenu] = useState(false);
  const [inputText, setInputText] = useState("");
  const [currRoom, setCurrRoom] = useState({});
  const [previewMedia, setPreviewMedia] = useState(false);
  const [mediaUrl, setMediaUrl] = useState("");
  const [editMessage, setEditMessage] = useState(null);
  const [readBy, setReadBy] = useState([]);
  const [isReply, setIsReply] = useState(false);
  const [replyMsg, setReplyMsg] = useState({});
  const [tempHeaderImage, setTempHeaderImage] = useState("");
  const [mediaType, setMediaType] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [record, setRecord] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [duration, setDuration] = useState(0);
  const [recordingFilePath, setRecordingFilePath] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedText, setSelectedText] = useState({});
  const [isSelf, setIsSelf] = useState(false);
  const [lstmsg, setlstmsg] = useState("");
  const [hoveredId, setHoveredId] = useState(null);
  const [isForward, setIsforward] = useState(false);
  const [forwardmsg, setForwardmsg] = useState([]);
  const [users, setUsers] = useState([]);
  const [onlineusers, setOnlineusers] = useState([]);
  const [searchtext, setsearchtext] = useState("");
  const [groupmembers, setGroupmembers] = useState([]);
  var [activeTabId, setActiveTabId] = useState(0);

  const rowsPerPage = 20;
  useEffect(() => {
    console.log("props.users", props.users);
    setUsers(props.users);
    setChatrooms(props.chatrooms);
  }, [props.users]);

  const colorArray = [
    "#FF8C6F", // Lightened shade of #FF5733 (Orange)
    "#027516", // Lightened shade of #33FF57 (Green)
    "#6A6FFF", // Lightened shade of #3357FF (Blue)
    "#ebcc34", // Lightened shade of #F3FF33 (Yellow)
    "#FF66F3", // Lightened shade of #FF33F3 (Pink)
    "#FFB74D", // Lightened shade of #FF8C00 (Dark Orange)
    "#00F0E1", // Lightened shade of #00CED1 (Dark Turquoise)
    "#FFE066", // Lightened shade of #FFD700 (Gold)
    "#0dba2a", // Lightened shade of #ADFF2F (Green Yellow)
    "#FF6F8C", // Lightened shade of #FF1493 (Deep Pink)
  ];
  const handleUserSearch = async searchText => {
    console.log(searchText);
    props.fetchUsersPagination(1, 200, searchText);
  };
  const debounced = useCallback(debounce(handleUserSearch, 500), []);

  let currentIndex = 0;
  const getRandomColor = () => {
    const color = colorArray[currentIndex];
    currentIndex = (currentIndex + 1) % colorArray.length; // Loop back to start
    return color;
  };

  // Menu handlers
  const handleMenuOpen = (event, text, self) => {
    console.log(text);
    event.stopPropagation();

    setSelectedText(text);
    setIsSelf(self);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Recording functions
  const startRecording = useCallback(() => {
    setRecord(true);
    setDuration(0);
    setAudioUrl(null);
    setIsRecording(true);
    if (timerRef.current) clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      setDuration(prev => prev + 1);
    }, 1000);
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (emojiRef.current && !emojiRef.current.contains(event.target)) {
        setIsSmiley(false);
      }
    }

    if (isSmiley) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSmiley]);

  const closeRecording = useCallback(() => {
    setRecord(false);
    setDuration(0);
    setAudioUrl(null);
    setIsRecording(false);
  }, []);

  const stopRecording = useCallback(() => {
    setRecord(false);
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  const getInitials = text => {
    if (!text) {
      return "NA";
    }
    let words = text?.split(" ").filter(word => word); // Split and remove extra spaces
    let initials =
      words.length > 2
        ? words.slice(0, 2).map(word => word[0]) // Pick first two words' initials
        : words.map(word => word[0]); // Pick only one if one word

    return initials.join("").toUpperCase();
  };
  const onStop = useCallback(async recordedBlob => {
    if (!recordedBlob || !recordedBlob.blob) {
      console.error("Recording failed or no blob received.");
      return;
    }

    // Ensure MIME type compatibility for iOS
    const fileBlob = new Blob([recordedBlob.blob], { type: "audio/mp4" });

    // Convert Blob to File for Upload
    const file = new File([fileBlob], `recording_${Date.now()}.m4a`, {
      type: "audio/mp4",
    });
    setRecordingFilePath(file);

    // Fix for iOS: Use Web Audio API instead of createObjectURL
    const audioURL = URL.createObjectURL(file);
    setAudioUrl(audioURL);
  }, []);

  const sendRecording = useCallback(() => {
    setIsSending(true);
    setMediaType("recording");

    const formdata = new FormData();
    formdata.append("file", recordingFilePath);
    formdata.append("documentId", Date.now());

    API.utility()
      .upload(formdata)
      .then(res => {
        setTempHeaderImage(res.data.data.path);
      })
      .catch(err => {
        setIsSending(false);
        console.error("Error uploading recording:", err);
        toast.error("Failed to upload recording. Please try again.");
      })
      .finally(() => {
        setIsRecording(false);
      });
  }, [recordingFilePath]);

  // Initialize chat when room changes
  useEffect(() => {
    if(props.currRoom?.id===currRoom.id){
      return;
    }
    if (currRoom.id) {
      store.dispatch({
        type: ACTION_TYPES_ROOMS.ROOM_UPDATE_COUNT,
        payload: currRoom,
      });
      console.log("old-room", currRoom.id, "new-room", props.currRoom?.id);
      props.PaginationUp(1, 20, currRoom.id);
      //API.msg().fetchPagination(1, 20, currRoom.id);

      // props.fetchmsgs(1, 20, currRoom.id);
    }


    try {
      window.parent.postMessage(
        { type: "SOCKET_ACTIVE_CHAT", active_room: props.currRoom },
        "*"
      );
    } catch (error) {
    }

    store.dispatch({
      type: ACTION_TYPES.MSG_FETCH_EMPTY,
      payload: [],
    });
    currRoomRef.current = props.currRoom;
    //props.getUnreadCount();

    // Reset chat state
    setChats([]);
    setPage(1);
    setFirstItemIndex(1000);
    setLoadingFinished(false);
    setIsLoading(false);

    // Switch socket room
    if (props.currRoom?.id) {
      socket.emit("switchRoom", props.currRoom.id, currRoom.id);
      setCurrRoom(props.currRoom);
      props.fetchmsgs(1, rowsPerPage, props.currRoom.id);
      setTimeout(() => scrollToBottom(), 500);

      const storedParams = localStorage.getItem("navParams");
      if (storedParams) {
        const navParams = JSON.parse(storedParams);
        if(navParams.is_msg_transfer){
          setInputText(navParams.message);
     
          localStorage.removeItem("navParams"); 
          }
      }

      if (inputRef.current) {
        inputRef.current.focus(); // Autofocus when room changes
      }
    }
  }, [props.currRoom]);

  // Socket event handlers
  useEffect(() => {
    function msgUpdateEvent(value, isack) {
      store.dispatch({
        type: ACTION_TYPES_ROOMS.ROOM_UPDATE,
        payload: value.room,
      });

      if (
        value.room.id === props.currRoom.id &&
        value.user._id !== authUser?.id
      ) {
        if (isack) {
          let newmsg = {
            _id: value.id,
            text: value.body,
            createdAt: new Date(),
            user: {
              _id: value.user._id,
              name: value.from,
            },
            ...value,
          };
          props.update_acks(newmsg);
        } else {
          let newmsg = {
            createdAt: new Date(),
            user: {
              _id: value.user._id,
              name: value.user.firstname,
            },
            ...value,
          };

          setChats(previousMessages => {
            // Update read status for current user's messages
            let updatedMessages = previousMessages.map(message => {
              if (
                message.user._id === authUser.id &&
                (!Array.isArray(message.readBy) || message.readBy.length === 0)
              ) {
                return {
                  ...message,
                  readBy: [{ userId: newmsg.user._id, readAt: new Date() }],
                };
              }
              return message;
            });

            return [...updatedMessages, newmsg];
          });

          // store.dispatch({
          //   type: ACTION_TYPES_ROOMS.ROOM_UPDATE,
          //   payload: value.room,
          // });
        }
      }
    }

    socket.on("msgupdate", msgUpdateEvent);

    return () => {
      socket.off("msgupdate", msgUpdateEvent);
      // props.getUnreadCount();
    };
  }, [props.currRoom, authUser]);

  // Update chat list when new messages are received
  useEffect(() => {
    //console.log(props.msgs);
    if (props.msgs && props.msgs.length) {
      const newArray = props.msgs.map(message => ({
        _id: message.id,
        user: {
          _id: message.user.id,
          name: message.user.firstname,
        },
        ...message,
      }));

      setChats(prev => [...newArray.reverse(), ...prev]);
      setFirstItemIndex(prev => prev - rowsPerPage);
      setIsLoading(false);
    }
  }, [props.msgs]);

  // Load more messages when scrolling up
  const loadMoreMessages = useCallback(() => {
    if (props.metamsgs?.totalPages === page || isLoading || loadingFinished) {
      if (props.metamsgs?.totalPages === page) {
        setLoadingFinished(true);
      }
      return;
    }

    setIsLoading(true);
    const nextPage = page + 1;
    setPage(nextPage);
    props.fetchmsgs(nextPage, rowsPerPage, props.currRoom.id);
  }, [
    isLoading,
    props.metamsgs,
    page,
    props.currRoom,
    props.fetchmsgs,
    rowsPerPage,
    loadingFinished,
  ]);

  // Scroll to bottom of chat
  const scrollToBottom = useCallback(() => {
    if (virtuosoRef.current) {
      virtuosoRef.current.scrollTo({ top: 999999, behavior: "instant" });
    }
  }, []);

  // Show media preview
  const showMediaPreview = useCallback(url => {
    setMediaUrl(url);
    setPreviewMedia(true);
  }, []);

  // Send message
  const sendMsg = useCallback(
    type => {
      if (editMessage?._id) {
        editMsg(inputText);
        return;
      }

      let msgobj = {
        text: inputText,
        user: authUser?.id,
        room: currRoom.id,
        type: type,
        image: "",
        video: "",
        audio: "",
        ack: "sent",
        reply: replyMsg._id ? replyMsg._id : "",
      };

      let temp_msgobj = {
        _id: new Date().toISOString(),
        createdAt: new Date().toISOString(),
        text: inputText,
        user: { _id: authUser.id },
        room: { id: currRoom?.id },
        type: type,
        image: "",
        video: "",
        audio: "",
        ack: "sent",
      };

      if (isReply) {
        temp_msgobj.reply = {
          _id: replyMsg._id,
          text: replyMsg.text,
          user: { firstname: replyMsg.user.firstname },
        };
        setIsReply(false);
      }

      // Handle different message types
      if (type === "media") {
        if (!tempHeaderImage || tempHeaderImage.length < 2) {
          toast.error("Couldn't upload the file. Please try again");
          return;
        }

        msgobj.type = mediaType;
        msgobj.text = tempHeaderImage;
        temp_msgobj.type = mediaType;
        temp_msgobj.text = tempHeaderImage;

        switch (mediaType) {
          case "image":
            msgobj.image = process.env.REACT_APP_IMG_URL + tempHeaderImage;
            temp_msgobj.image = process.env.REACT_APP_IMG_URL + tempHeaderImage;
            break;
          case "video":
            msgobj.video = process.env.REACT_APP_IMG_URL + tempHeaderImage;
            temp_msgobj.video = process.env.REACT_APP_IMG_URL + tempHeaderImage;
            break;
          case "audio":
          case "recording":
            msgobj.audio = process.env.REACT_APP_IMG_URL + tempHeaderImage;
            temp_msgobj.audio = process.env.REACT_APP_IMG_URL + tempHeaderImage;
            break;
        }

        setTempHeaderImage("");
        setIsSending(false);
      }

      // Add temporary message to chat
      setChats(prev => [...prev, temp_msgobj]);
      setInputText("");
      setIsReply(false);
      setReplyMsg({});

      // Update message with database ID when saved
      const onSuccess = dbResponse => {
        setChats(previousMessages =>
          previousMessages.map(message =>
            message._id === temp_msgobj._id
              ? { ...message, _id: dbResponse._id }
              : message,
          ),
        );
        console.log("dbResponse", dbResponse);
        let nroom = dbResponse.room;
        nroom.unread_count = 0;
        store.dispatch({
          type: ACTION_TYPES_ROOMS.ROOM_UPDATE,
          payload: nroom,
        });
      };

      props.sendMsg(msgobj, onSuccess);
    },
    [
      authUser,
      currRoom,
      editMessage,
      inputText,
      isReply,
      mediaType,
      replyMsg,
      tempHeaderImage,
    ],
  );
  const openbottomsheet = () => {
    props.fetchUsersPagination(1, 200);
    setIsforward(true);
  };

  const contactclickbottomsheet = async item => {
    console.log(item, global.loginData);
    debugger;
    let room = {};

    room.name = item.name;
    room.owner = authUser?.id;
    room.members = [item.id];
    room.type = "individual";
    const onSuccess = async item => {
      await forwardMsg(item.id);

      console.log("createchatroom", item);
      setGroupmembers([]);
      setIsforward(false);
      setForwardmsg([]);
      props.navigatetoroom(item);
    };
    props.createRoom(room, onSuccess);
  };

  const forwardMsg = async chatroom_id => {
    forwardmsg.forEach(item => {
      //console.log("Forwarded Message:", item);
      let msgobj = {
        text: item?.text,
        user: authUser?.id ? authUser?.id : "",
        room: chatroom_id,
        type: item?.type,
        image: item?.image,
        video: item?.video,
        audio: item?.audio,
        ack: "sent",
        reply: "",
        forward: item?._id,
        reply: forwardmsg?.reply?._id || "",
      };

      const onSuccess = message => {
        console.log(message);
        let nroom = message.room;
        nroom.unread_count = 0;
        store.dispatch({
          type: ACTION_TYPES_ROOMS.ROOM_UPDATE,
          payload: nroom,
        });
      };
      props.sendMsg(msgobj, onSuccess);
    });
  };

  const editMsg = newMessages => {
    let editedmsg = {
      text: newMessages,
      edit: new Date().toISOString(),
    };
    setChats(previousMessages =>
      previousMessages.map(message =>
        message._id === editMessage?._id
          ? {
              ...message,
              text: editedmsg.text,
              edit: editedmsg.edit,
            }
          : message,
      ),
    );

    const onSuccess = dbResponse => {
      console.log("dbResponse", dbResponse);
      setEditMessage(null);
      setInputText("");
      setIsReply(false);
    };

    props.updateMsg(editMessage?._id, editedmsg, onSuccess);
    return;
  };

  // Handle file upload
  const handleFileChange = useCallback(async e => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.size > MAX_FILE_SIZE) {
      toast.error("File too large! Please upload a file smaller than 5MB.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("documentId", Date.now());

    try {
      setIsSending(true);
      const res = await API.utility().upload(formData);
      setTempHeaderImage(res.data.data.path);
    } catch (err) {
      console.error("Upload error:", err);
      toast.error("Upload failed. Please try again later.");
    } finally {
      setIsSending(false);
    }
  }, []);
  const handleChangeTab = id => {
    setActiveTabId(id);
    // setErrorMessage(null)
  };
  // Send media message after upload completes
  useEffect(() => {
    if (tempHeaderImage && tempHeaderImage.length > 2) {
      sendMsg("media");
      setShowMediaMenu(false);
    }
  }, [tempHeaderImage, sendMsg]);

  // Open file picker
  const openFilePicker = useCallback(type => {
    if (fileInputRef.current) {
      let acceptType = "";
      switch (type) {
        case "image":
          acceptType = "image/*";
          break;
        case "video":
          acceptType = "video/*";
          break;
        case "audio":
          acceptType = "audio/*";
          break;
        case "document":
          acceptType = ".pdf,.doc,.docx,.xls,.xlsx,.txt";
          break;
      }
      setMediaType(type);
      fileInputRef.current.accept = acceptType;
      fileInputRef.current.click();
    }
  }, []);

  // Format time for recording display
  const formatTime = time => {
    const hrs = Math.floor(time / 3600);
    const mins = Math.floor((time % 3600) / 60);
    const secs = Math.floor(time % 60);

    let ret = "";
    if (hrs > 0) {
      ret += `${hrs}:${mins < 10 ? "0" : ""}`;
    }
    ret += `${String(mins).padStart(2, "0")}:`;
    ret += `${String(secs).padStart(2, "0")}`;
    return ret;
  };

  // Render message text with reply formatting
  const MessageText = useCallback(data => {
    if (data.type !== "text") return null;

    let replyto = "";
    if (data?.reply?._id?.length > 3) {
      replyto = `
        <div style="font-size: 15px; margin-bottom: 4px; font-weight: 600; color: #333;">
          ${data?.reply?.user?.firstname}
        </div>
        <div class="clamp" style="font-size: 14px; color: #555;">
          ${data?.reply?.text}
        </div>
      `;

      replyto = `
        <a href="${data?.reply?._id}" style="font-size: 14px; padding: 5px; background-color: #f9f9f9; border-radius: 5px; margin-bottom: 8px; border-left: 4px solid #21386a; text-decoration: none; float:left; width:100%">
          ${replyto}
        </a>
      `;
    }

    const msg = `
      ${replyto}
      <div style="font-size: 15px; word-wrap: break-word; white-space: pre-wrap; margin: 0 !important;">${data.text}</div>
    `;

    return <div>{ReactHtmlParser(msg)}</div>;
  }, []);

  // Render message content based on type
  const renderMessageContent = useCallback(
    data => {
      switch (data.type) {
        case "image":
          return (
            <div>
              <a onClick={() => showMediaPreview(data.image)}>
                <img
                  loading="lazy"
                  src={data.image}
                  className="mt-2"
                  style={{
                    objectFit: "cover",
                    height: 200,
                    width: 300,
                  }}
                  alt="Image attachment"
                />
              </a>
            </div>
          );
        case "audio":
        case "recording":
          return (
            <audio controls src={data.audio} style={{ height: 35, width: 300 }}>
              Your browser does not support the audio element.
            </audio>
          );
        case "video":
          return (
            <div>
              <a onClick={() => showMediaPreview(data.video)}>
                <video
                  src={data.video}
                  style={{ height: 200, width: 300 }}
                  className="mt-2"
                  controls={false}
                />
              </a>
            </div>
          );
        case "document":
          return (
            <div style={{ height: 200, width: 300, position: "relative" }}>
              <embed
                src={process.env.REACT_APP_IMG_URL + data.text}
                className="mt-2"
                style={{ height: 200, width: 300 }}
              />
              <a
                className="w-100 h-100"
                onClick={() =>
                  showMediaPreview(process.env.REACT_APP_IMG_URL + data.text)
                }
                style={{ position: "absolute", left: 0, top: 0 }}
              ></a>
            </div>
          );
          case 'contact':
            return (
              data?.contacts?.map(contact => (
                <div className="d-flex align-items-center bg-white p-2 mb-2 rounded shadow-sm" key={contact.phoneNumbers[0]?.number}>
                  {/* Avatar */}
                  <div className="rounded-circle d-flex align-items-center justify-content-center bg-secondary text-white fw-bold"
                       style={{ width: "40px", height: "40px" }}>
                    {contact.givenName?.charAt(0)}
                  </div>
          
                  {/* Contact Info */}
                  <div className="ms-3 flex-grow-1">
                    <h6 className="mb-0">{contact.givenName} {contact.familyName}</h6>
                    <small className="text-muted">{contact.phoneNumbers[0]?.number || 'No number available'}</small>
                  </div>
          
                 
                </div>
              ))
            );
        break;
        case "text":
          return MessageText(data);
        default:
          return null;
      }
    },
    [MessageText, showMediaPreview],
  );

  const update_forward_array = (currmsg, isadd) => {
    debugger;
    setForwardmsg(prev => {
      if (isadd) {
        if (!prev.some(msg => msg._id === currmsg._id)) {
          return [...prev, currmsg];
        }
      } else {
        // Remove the object with the given _id
        return prev.filter(msg => msg._id !== currmsg._id);
      }
      return prev; // Return unchanged if no modification is needed
    });
  };
  // Memoize the message item renderer for performance
  const itemContent = useCallback(
    (index, data) => {
      const isCurrentUser =
        data?.user?._id === authUser?.id || data?.user?.id === authUser?.id;
      const isMessageForwarded = forwardmsg.some(msg => msg._id === data._id);
      return (
        <>
          {forwardmsg.length > 0 && (
            <div style={{ position: "absolute", left: "1%", zIndex: 11 }}>
              {isMessageForwarded ? (
                <a onClick={() => update_forward_array(data, false)}>
                  <CheckOutlined />{" "}
                </a>
              ) : (
                <a onClick={() => update_forward_array(data, true)}>
                  <CheckBoxOutlineBlank />
                </a>
              )}
            </div>
          )}
          <li
            className={isCurrentUser ? "repaly me-2" : "sender ms-2"}
            key={index}
            onMouseEnter={() => setHoveredId(data.id)}
            onMouseLeave={() => setHoveredId(null)}
          >
            <p>
              {currRoom.type === "group" && !isCurrentUser && (
                <div className="fw-bold" style={{ color: "#21386b" }}>
                  {data?.user?.firstname}
                </div>
              )}

              {renderMessageContent(data)}

              <small className="time" title={data?.ack}>
                {data?.forward?.length && <span>Forwarded &nbsp; &nbsp;</span>}
                {data?.edit?.length && <span>Edited &nbsp; &nbsp;</span>}
                <span
                  className="float-end ms-2 cursor-pointer"
                  onMouseLeave={() => setHoveredId(data.id)}
                  onMouseEnter={() => setHoveredId(null)}
                  style={{ width: 20, height: 10, display: "inline-block" }}
                >
                  {isCurrentUser && (
                    <>
                      {currRoom.type === "individual" ? (
                        data?.readBy?.length ? (
                          <Tooltip
                            title={
                              <div>
                                <strong>Read Info</strong>
                                <ul
                                  style={{
                                    margin: 0,
                                    paddingLeft: 0,
                                    textAlign: "left",
                                    listStyleType: "none",
                                  }}
                                >
                                  {data.readBy.length > 0 ? (
                                    data.readBy.map((data, index) => (
                                      <li key={index}>
                                        {data?.userId?.firstname}{" "}
                                        {data?.userId?.lastname} -{" "}
                                        {moment(data?.readAt).format(
                                          "hh:mm a DD/MM/YYYY",
                                        )}
                                      </li>
                                    ))
                                  ) : (
                                    <li>No read info available</li>
                                  )}
                                </ul>
                              </div>
                            }
                          >
                            <DoneAll
                              style={{ fontSize: 14, color: "#00B3FF" }}
                            />
                          </Tooltip>
                        ) : (
                          <DoneAll style={{ fontSize: 14, color: "#585858" }} />
                        )
                      ) : data?.readBy?.length === currRoom.members?.length ? (
                        <DoneAll style={{ fontSize: 14, color: "#00B3FF" }} />
                      ) : (
                        <DoneAll style={{ fontSize: 14, color: "#585858" }} />
                      )}
                    </>
                  )}
                </span>
                <span className="float-end">
                  {moment(data?.createdDate).format("DD-MMM-YY hh:mm a")}
                </span>
              </small>
            </p>

            {hoveredId === data.id && (
              <span
                className="float-end verticon"
                style={{
                  right: isCurrentUser ? 20 : "auto",
                  backgroundColor: isCurrentUser ? "#cde1f5" : "#f5f5f5",
                }}
              >
                <a
                  aria-controls="message-menu"
                  aria-haspopup="true"
                  className="me-2 text-black"
                  onClick={e => handleMenuOpen(e, data, isCurrentUser)}
                >
                  <KeyboardArrowDown
                    style={{ fontSize: 22, color: "#383838" }}
                  />
                </a>
              </span>
            )}
          </li>
        </>
      );
    },
    [authUser, currRoom, handleMenuOpen, renderMessageContent],
  );
  useEffect(() => {
    setPage(1);
    console.log("searchtext", searchtext);
    props.fetchRoomsPagination(1, 20, authUser.id, searchtext);
  }, [searchtext]);
  // Render chat component

  return (
    <>
      {chats.length > 0 ? (
        <div
          style={{
            height: "90vh",
            border: "1px solid #ccc",
            overflow: "hidden",
          }}
        >
          <Virtuoso
            ref={virtuosoRef}
            components={{
              Header: () => (
                <div style={{ textAlign: "center", padding: "1rem" }}>
                  {loadingFinished
                    ? "End of conversation"
                    : "Loading messages..."}
                </div>
              ),
              EmptyPlaceholder: () => (
                <div style={{ textAlign: "center", padding: "1rem" }}>
                  No Messages
                </div>
              ),
            }}
            style={{
              height: "100%",
              overflowX: "hidden",
              flex: 1,
              backgroundImage: `url(${wsbg})`,
            }}
            firstItemIndex={firstItemIndex}
            initialTopMostItemIndex={chats.length - 1}
            data={chats}
            startReached={loadMoreMessages}
            alignToBottom={true}
            followOutput="auto"
            itemContent={itemContent}
          />
        </div>
      ) : (
        <div
          className="chat-bg"
          style={{
            height: "57vh",
            overflowX: "hidden",
            backgroundImage: `url(${wsbg})`,
            flex: 1,
          }}
        ></div>
      )}

      <div className="send-box">
        <form
          action=""
          className="mb-0"
          style={{ alignItems: isReply ? "end" : "" }}
        >
          <>
            <a
              className="me-2 text-black d-lg-block d-none"
              onClick={() => setIsSmiley(!isSmiley)}
            >
              <EmojiEmotionsOutlined />
            </a>
            {isSmiley && (
              <div
                ref={emojiRef}
                style={{
                  position: "absolute",
                  left: 10,
                  bottom: 70,
                  background: "#fff",
                  zIndex: 999,
                }}
              >
                <EmojiPicker
                  className="position-absolute"
                  onEmojiClick={(event, emojiObject) => {
                    setInputText(prevInput => prevInput + emojiObject.emoji);
                  }}
                />
              </div>
            )}

            <a
              aria-controls="simple-menu"
              aria-haspopup="true"
              ref={inputEl}
              className="me-2 text-black d-lg-block d-none"
              onClick={() => setShowMediaMenu(!showMediaMenu)}
            >
              <AttachFileOutlined />
            </a>

            <Menu
              id="simple-menu"
              keepMounted
              anchorEl={inputEl.current}
              open={showMediaMenu}
              onClose={() => setShowMediaMenu(false)}
              getContentAnchorEl={null} 
            >
              <MenuItem onClick={() => openFilePicker("image")}>Image</MenuItem>
              <MenuItem onClick={() => openFilePicker("video")}>Video</MenuItem>
              <MenuItem onClick={() => openFilePicker("audio")}>Audio</MenuItem>
              <MenuItem onClick={() => openFilePicker("document")}>
                Document
              </MenuItem>
            </Menu>

            {record ? (
              <a className="me-2 text-black d-lg-block d-none" onClick={stopRecording}>
                <StopRounded size={36} />
              </a>
            ) : (
              <a className="me-2 text-black d-lg-block d-none" onClick={startRecording}>
                <Mic size={36} />
              </a>
            )}

            <div
              className={`align-items-center justify-content-between w-100 ${
                isRecording ? "d-flex" : "d-none"
              }`}
            >
              <span className="me-3">{formatTime(duration)}</span>

              {audioUrl ? (
                <>
                  <audio
                    controls
                    src={audioUrl}
                    className="w-75"
                    style={{ height: 35 }}
                  >
                    Your browser does not support the audio element.
                  </audio>
                  <button
                    className="btn-sm btn btn-link"
                    onClick={closeRecording}
                  >
                    <Close />
                  </button>
                </>
              ) : (
                <ReactMic
                  record={record}
                  className="rounded w-25"
                  onStop={onStop}
                  strokeColor="#000"
                  backgroundColor="#fff"
                  mimeType="audio/mp4"
                />
              )}

              <button
                type="button"
                className="btn btn-link text-center text-black"
                onClick={sendRecording}
                disabled={isSending || !recordingFilePath}
              >
                <SendSharp />
              </button>
            </div>

            {!isRecording && (
              <>
                <div style={{ flex: 1 }}>
                  {isReply && (
                    <>
                      <div className="row">
                        <div className="col-lg-11 mb-2">
                          <div
                            style={{
                              borderLeft: "4px solid #21386b",
                              borderRadius: "10px",
                              padding: "10px",
                              // Added padding for better visibility
                            }}
                          >
                            <strong>{replyMsg?.user?.firstname}</strong>
                            <p className="mb-0 clamp">{replyMsg?.text}</p>
                          </div>
                        </div>

                        <div className="col-lg-1 align-content-center">
                          <button
                            className="btn text-black"
                            onClick={() => setIsReply(false)}
                          >
                            <Close />
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  <TextareaAutosize
                    ref={inputRef}
                    autoFocus={true}
                    minRows={1}
                    maxRows={5}
                    value={inputText}
                    onChange={e => setInputText(e.target.value)}
                    onKeyDown={e => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        if (inputText.trim()) {
                          sendMsg("text");
                        }
                      }
                    }}
                    className="form-control w-100"
                    placeholder="Write message…"
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-link text-center text-black"
                  onClick={() => sendMsg("text")}
                  disabled={!inputText.trim().length || isSending}
                >
                  <SendSharp />
                </button>
              </>
            )}

            {forwardmsg.length > 0 && (
              <div
                style={{
                  position: "absolute",
                  height: 65,
                  bottom: 0,
                  width: "100%",
                  backgroundColor: "aliceblue",
                  zIndex: 10,
                  left: 0,
                }}
              >
                <div className="d-flex justify-content-between mt-3">
                  <button
                    type="button"
                    className="btn btn-link text-center text-black"
                    onClick={() => {
                      setForwardmsg([]);
                    }}
                  >
                    <Close />
                  </button>
                  <div>selected {forwardmsg.length} messages</div>
                  <button
                    type="button"
                    className="btn btn-link text-center text-black mr-2"
                    onClick={() => openbottomsheet()}
                  >
                    <SendSharp />
                  </button>
                </div>
              </div>
            )}
            
          </>

        </form>
        <div className="d-block d-md-none mt-2" style={{height:24}}>
            <a
              className="me-2 text-black float-start"
              onClick={() => setIsSmiley(!isSmiley)}
            >
              <EmojiEmotionsOutlined />
            </a>

            <a
              aria-controls="simple-menu"
              aria-haspopup="true"
              ref={inputEl}
              className="me-2 text-black float-start"
              onClick={() => setShowMediaMenu(!showMediaMenu)}
            >
              <AttachFileOutlined />
            </a>

            {record ? (
              <a className="me-2 text-black float-start" onClick={stopRecording}>
                <StopRounded size={36} />
              </a>
            ) : (
              <a className="me-2 text-black float-start" onClick={startRecording}>
                <Mic size={36} />
              </a>
            )}
            </div>
      </div>

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        data-type={mediaType}
      />

      <PreviewModal
        visible={previewMedia}
        setVisible={setPreviewMedia}
        urls={[mediaUrl]}
      />

      <Menu
        id="message-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
         <MenuItem
          onClick={() => {
            // Handle reply to message
            setIsReply(true);
            setReplyMsg(selectedText);
            handleMenuClose();
            // Focus input field
            setTimeout(() => {
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }, 100);
          }}
        >
          &nbsp; Reply &nbsp;
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigator.clipboard.writeText(selectedText.text);
            toast.success("Copied!");
            handleMenuClose();
          }}
        >
          &nbsp; Copy
        </MenuItem>
        {isSelf && (
          <MenuItem
            onClick={() => {
              // Handle edit message
              setEditMessage(selectedText);
              setInputText(selectedText.text);
              handleMenuClose();
            }}
          >
            &nbsp; Edit &nbsp;
          </MenuItem>
        )}
        {isSelf && (
          <MenuItem
            onClick={() => {
              // Handle delete message
              if (
                window.confirm("Are you sure you want to delete this message?")
              ) {
                const onSuccess = v => {
                  toast.success("Message deleted");
                };
                props.deleteMsg(selectedText._id, onSuccess);
                setChats(prevMessages =>
                  prevMessages.filter(e => e._id !== selectedText._id),
                );
                // props.deleteMsg(selectedText._id, () => {
                //   setChats(chats.filter(msg => msg._id !== selectedText._id));
                //   toast.success("Message deleted");
                // });
              }
              handleMenuClose();
            }}
          >
            &nbsp; Delete &nbsp; &nbsp; &nbsp;
          </MenuItem>
        )}
       

         
          <MenuItem
            onClick={() => {
              // Handle edit message
              console.log(selectedText);
              // setIsforward(true);
              setForwardmsg([selectedText]);

              handleMenuClose();
            }}
          >
            &nbsp; Forward &nbsp;
          </MenuItem>
        
      </Menu>

      <Dialog
        open={isForward}
        onClose={() => setIsforward(!isForward)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"md"}
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.85)" }, // Black with opacity
        }}
      >
        <DialogTitle id="alert-dialog-title">Forward to</DialogTitle>
        <DialogContent>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => handleChangeTab(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Contacts" value={0} />
            <Tab label="Recent" value={1} />
          </Tabs>

          {activeTabId == 0 && (
            <div className="contactlist">
              <input
                placeholder="Search"
                className="form-control mb-2 mt-n2"
                onChange={e => debounced(e.target.value)}
                style={{
                  borderWidth: 1,
                  borderRadius: 10,
                  borderColor: "#ccc",
                  paddingLeft: 10,
                  height: 40,
                }}
              />
              <TableVirtuoso
                style={{ height: 400 }}
                data={users?.sort(
                  (a, b) =>
                    onlineusers?.includes(b?._id) -
                    onlineusers?.includes(a?._id),
                )}
                components={{
                  Table: props => (
                    <table className="table">{props.children}</table>
                  ),
                  TableBody: React.forwardRef((props, ref) => (
                    <tbody {...props} ref={ref} />
                  )),
                  TableRow: ({ item, ...props }) => (
                    <tr
                      {...props}
                      onClick={() => contactclickbottomsheet(item)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>
                        <div className="flex-shrink-0">
                          {onlineusers?.includes(item._id) ? (
                            <FiberManualRecord
                              style={{
                                fontSize: 10,
                                color: "#50C878",
                                position: "absolute",
                                zIndex: 1,
                              }}
                            />
                          ) : (
                            ""
                          )}

                          <Avatar
                            style={{
                              backgroundColor: getRandomColor(),
                              width: 30,
                              height: 30,
                            }}
                            sizes="small"
                          >
                            {getInitials(item.firstname) || "NA"}
                          </Avatar>
                        </div>
                      </td>
                      <td className="border-bottom py-2 w-50">
                        {item.firstname} {item.lastname}
                      </td>
                      <td className="border-bottom py-2 w-50">
                        {item.division}
                      </td>
                      <td className="border-bottom py-2 w-50">{item.email}</td>
                    </tr>
                  ),
                }}
                itemContent={() => null} // Remove itemContent, as we're defining TableRow separately
              />
            </div>
          )}
          {activeTabId == 1 && (
            <div className="contactlist">
              <input
                type="search"
                className="form-control border-radius-40"
                id="inlineFormInputGroup"
                placeholder="Search.."
                value={searchtext}
                onChange={e => setsearchtext(e.target.value)}
              />
              <TableVirtuoso
                style={{ height: 400 }}
                data={props.rooms}
                components={{
                  Table: props => (
                    <table className="table">{props.children}</table>
                  ),
                  TableBody: React.forwardRef((props, ref) => (
                    <tbody {...props} ref={ref} />
                  )),
                }}
                itemContent={(index, data) => (
                  <div
                    className={
                      "d-flex align-items-center px-2 py-2.5 " +
                      (currRoom.id === data.id ? "bg-lightgray" : "")
                    }
                    onClick={() => {
                      setsearchtext("");
                      forwardMsg(data.id); 
                      setIsforward(false);
                      setForwardmsg([]);
                      props.navigatetoroom(data);
                     
                    }}
                    key={index}
                    style={{ width: "100%" }}
                  >
                    {/* Avatar */}
                    <div className="flex-shrink-0">
                      {onlineusers?.includes(
                        data.type === "individual"
                          ? authUser?.id === data?.owner?._id
                            ? data?.members[0]?._id
                            : data?.owner?._id
                          : data?._id,
                      ) ? (
                        <FiberManualRecord
                          style={{
                            fontSize: 14,
                            color: "#50C878",
                            position: "absolute",
                            zIndex: 1,
                          }}
                        />
                      ) : (
                        ""
                      )}

                      <Avatar style={{ backgroundColor: getRandomColor() }}>
                        {getInitials(
                          data.type === "individual"
                            ? authUser.id === data.owner?._id
                              ? data.members[0]?.name
                              : data.owner?.name
                            : data.name,
                        ) || "NA"}
                      </Avatar>
                    </div>

                    {/* Chat Details */}
                    <div className="flex-grow-1 ms-2">
                      {/* Badges at the Top Right */}
                      <div className="d-flex justify-content-between align-items-center mb-1">
                        <span className="fw-semibold opacity-75 text-black text-truncate">
                          {data.type === "individual"
                            ? authUser.id === data.owner?._id
                              ? data.members[0]?.name
                              : data.owner?.name
                            : data.name}
                        </span>
                        {/* {data.id} */}
                        {data?.unread_count > 0 && (
                          <div className="d-flex">
                            <span
                              className="badgeimg ms-1"
                              style={{ fontSize: 9 }}
                            >
                              {data?.unread_count}
                            </span>
                          </div>
                        )}
                      </div>

                      {/* Last Message (Truncated) */}

                      {/* Date at the Bottom Right (Reduced Size) */}
                      <div className="d-flex justify-content-between">
                        {/* Last Message (Left-Aligned) */}
                        <small
                          className="text-muted text-truncate"
                          style={{
                            maxWidth: "200px",
                            flexGrow: 1,
                          }} // Ensures it takes available space
                        >
                          {data?.lastmsg?.replace(/<[^>]*>/g, " ")}
                        </small>

                        {/* Date (Right-Aligned, Smaller Font) */}
                        <small
                          className="text-muted ms-2"
                          style={{
                            fontSize: "10px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {moment(data?.updatedAt).format("D MMM, h:mm a")}
                        </small>
                      </div>
                    </div>
                  </div>
                )}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsforward(!isForward)} color="primary">
            Cancel
          </Button>

        </DialogActions>
      </Dialog>
    </>
  );
};

// Edit message function (needs to be added)
// const editMsg = text => {
//   // Implementation will depend on your API structure
//   // This is just a placeholder
//   console.log("Edit message:", text);
// };

const mapStateToProps = state => ({
  msgs: state.msg.msgs,
  metamsgs: state.msg.metaMsg,
  vcroom: state.room.vcroom,
  onlineusers: state.user.onlineusers,
  users: state.user.users,
  metaUser: state.user.metaUser,
  rooms: state.room.rooms,
});

const mapActionToProps = {
  fetchmsgs: msgs.Pagination,
  sendMsg: msgs.create,
  updateMsg: msgs.update,
  deleteMsg: msgs.Delete,
  fetchsocketmsg: msgs.fetchsocketmsg,
  update_acks: msgs.update_acks,
  updatRoom: rooms.update,
  getUnreadCount: rooms.getUnreadCount,
  fetchUsersPagination: users.Pagination,
  updateRoom: rooms.update,
  PaginationUp: msgs.PaginationUp,
  createRoom: rooms.create,
};

export default connect(mapStateToProps, mapActionToProps)(Chatbox);
