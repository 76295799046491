import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import { Avatar } from "@material-ui/core";
import Chatbox from "./chatbox";
import "./style.css";
import * as actions from "./../../actions/room";
import * as users from "./../../actions/user";
import { ACTION_TYPES as ACTION_TYPES_USERS } from "./../../actions/user";
import { ACTION_TYPES as ACTION_TYPES_ROOMS } from "./../../actions/room";
import { debounce } from "lodash";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import {
  MoreVert,
  AddCircleOutline,
  FiberManualRecord,
  Check,
  Favorite, Star, StarBorder
} from "@material-ui/icons";
import { TableVirtuoso, Virtuoso } from "react-virtuoso";
import Checkbox from "@material-ui/core/Checkbox";
import { toast } from "react-toastify";
import { socket } from "../../context/socket";
import { isMobile } from "react-device-detect";
import moment from "moment";
import NotificationSound from "./../../images/notification-sound.mp3";
import { AuthContext } from "../../context/AuthContext";
import logo from "../../images/logo.png";
import { store } from "../../store/store";
import Chip from "@material-ui/core/Chip";
import API from "../../utils/api";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

const Inbox = ({ ...props }) => {
  const history = useHistory();

  let isTabinactive = false;
  const location = useLocation();

  const { getAuthUser } = useContext(AuthContext);
  const authUser = getAuthUser();
  const audioPlayer = useRef(null);

  const [loading, setLoading] = useState(false);
  const [isgroup, setIsgroup] = useState(false);
  const [groupmembers, setGroupmembers] = useState([]);
  const [groupname, setgroupname] = useState("");
  const [chatrooms, setChatrooms] = useState([]);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [userpage, setUserpage] = useState(1);
  const [roomsperpage, setroomsperpage] = useState(20);
  const [onlineusers, setOnlineusers] = useState([]);
  const [searchtext, setsearchtext] = useState("");
  const [currRoom, setcurrRoom] = useState(false);
  const [contact, setContact] = useState({});
  const [ischatboxactive, setischatboxactive] = useState(false);
  const [roomname, setRoomname] = useState("");
  const currRoomRef = useRef(currRoom);
  const [isCreate, setIscreate] = useState(false);
  const [isTabinact, setisTabinact] = useState(null);
  const [filter, setFilter] = useState('all');

  const [navParams, setNavParams] = useState(location.state);
  const storedParams = localStorage.getItem("navParams");
  
  useEffect(() => {
    
    if (!location.state || storedParams) {
      const storedParams = localStorage.getItem("navParams");
      if (storedParams) {
        //setNavParams(JSON.parse(storedParams));
        console.log("Stored Params:", storedParams);
     
        let navuser = JSON.parse(storedParams)
        API.user().getByUserName(navuser.navigate_to_user).then(res => {  
          console.log("getByUserName", res.data);
         
          let navParam
          navParam = res.data;
          navParam._id = res.data.id;
          navParam.nav = JSON.parse(storedParams)
          setNavParams(navParam);
          if(!navuser.is_msg_transfer){
          localStorage.removeItem("navParams"); 
          }
        })
      }
    }
  }, [location.state, storedParams]);

  useEffect(() => {
    if (navParams?._id) {
      
      console.log("navParams", navParams);
      contactclickbottomsheet(navParams)
      setNavParams(null);
      window.history.pushState(null,"",'https://chat.alzaabigroup.com/');

    }
  }, [navParams]);



  const onVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      isTabinactive = false;
      document.title = "Engage Chat";
      setisTabinact(false);
    
      console.log("Tab reopened, refetch the data!");
    } else {
      console.log("Tab closed, refetch the data!");
      isTabinactive = true;
      setisTabinact(true);
    }
  };

  // useEffect(() => {
  //   console.log("isTabinact", isTabinact);
  //   if (isTabinact === false) props.fetchRoomsPagination(1, roomsperpage, authUser.id);
  // }, [isTabinact]);

  useEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange);

  }, []);

  useEffect(() => {
    console.log("filter", filter);

    filter === "all"
      ? props.fetchRoomsPagination(1, roomsperpage, authUser.id, searchtext)
      : filter === "unread"
      ? props.fetchRoomsPagination(1, roomsperpage, authUser.id, searchtext, filter)
      : filter === "groups"
      ? props.fetchRoomsPagination(1, roomsperpage, authUser.id, searchtext, filter)
      : filter === "favorites"
      ? props.fetchRoomsPagination(1, roomsperpage, authUser.id, searchtext, filter)
      : props.fetchRoomsPagination(1, roomsperpage, authUser.id, searchtext);

  }, [filter]);

  useEffect(() => {

    if (authUser.id?.length > 0) {
     

      socket.emit("login", authUser.id);
      socket.on("onlineUpdate", Obj => {
        store.dispatch({
          type: ACTION_TYPES_USERS.USER_ONLINE,
          payload: Obj,
        });
      });

      const msgupdateEvent = room => {
        console.log("roomlistupdate", currRoomRef.current?.id);
        console.log("isTabinactive", isTabinactive);
        if (isTabinactive) {
          document.title = "🔔 New message";
          audioPlayer.current.play();
        }
        
        if (currRoomRef.current?.id !== room.id) {
          
          store.dispatch({
            type: ACTION_TYPES_ROOMS.ROOM_UPDATE,
            payload: room,
          });
        }

        
        try {
          window.parent.postMessage(
            { type: "SOCKET_CHAT_NOTIFICATION", room },
            "*"
          );
        } catch (error) {
        }
        // else{
         
        //   store.dispatch({
        //     type: ACTION_TYPES_ROOMS.ROOM_UPDATE_COUNT_ONLY_RESET,
        //     payload: currRoomRef.current,
        //   });
        // }

        // store.dispatch({
        //   type: ACTION_TYPES_ROOMS.ROOM_FETCH_UNREAD_COUNT,
        //   payload: room?.unread_rooms_count || 0,
        // });
        // if (chatroomsRef.current.some(e => e.id === value.id)) {
        //   setChatrooms(chatroomsRef.current.map(x => x.id === value.id ?
        //     { ...x, lastmsg: value.lastmsg, isnew: value.isnew, iscampaign: value.iscampaign, updatedAt: value.updatedAt, isagent: value.isagent }
        //     : x));
        // } else {
        //   setChatrooms(previous => [value, ...previous]);
        // }
      };
      socket.emit("switchPhonenumber", authUser.id, authUser.id);

      socket.on("roomlistupdate", msgupdateEvent);
    }

    return () => {
      socket.off("onlineUpdate");
      socket.off("roomlistupdate");
    };
  }, [authUser.id]);

  const clear_unread_count = async id => {
    setChatrooms(prevChatrooms =>
      prevChatrooms.map(x => (x.id === id ? { ...x, unread_count: 0 } : x)),
    );
  };

  useEffect(() => {
    setOnlineusers(props.onlineusers);
   // console.log("props.onlineusers", props.onlineusers);
  }, [props.onlineusers]);

  const handleChangePage = () => {
    console.log("rooms end");
    if (page < props.metaRoom.totalPages + 1) {
      //console.log(page + 1);
      setPage(page + 1);
      props.fetchRoomsPagination(page + 1, roomsperpage, authUser.id,searchtext, filter);
    }
  };

  const colorArray = [
    '#FF8C6F', // Lightened shade of #FF5733 (Orange)
    '#027516', // Lightened shade of #33FF57 (Green)
    '#6A6FFF', // Lightened shade of #3357FF (Blue)
    '#ebcc34', // Lightened shade of #F3FF33 (Yellow)
    '#FF66F3', // Lightened shade of #FF33F3 (Pink)
    '#FFB74D', // Lightened shade of #FF8C00 (Dark Orange)
    '#00F0E1', // Lightened shade of #00CED1 (Dark Turquoise)
    '#FFE066', // Lightened shade of #FFD700 (Gold)
    '#0dba2a', // Lightened shade of #ADFF2F (Green Yellow)
    '#FF6F8C', // Lightened shade of #FF1493 (Deep Pink)
  ];
  let currentIndex = 0;
  const getRandomColor = () => {
    const color = colorArray[currentIndex];
    currentIndex = (currentIndex + 1) % colorArray.length; // Loop back to start
    return color;
  };

  useEffect(() => {
    setChatrooms(props.rooms);
 
  }, [props.rooms]);

  // useEffect(() => {
  //     console.log('chatrooms', chatrooms)
  // }, [chatrooms]);

  useEffect(() => {
    setPage(1);
    console.log("searchtext", searchtext);
    props.searchRooms(1, 20, authUser.id, searchtext);
  }, [searchtext]);

  const onRoomClick = item => {
    if(item.id===currRoom.id){
      return;
    }
    let room_name =
      item.type === "individual"
        ? authUser.id === item.owner?._id
          ? item.members[0]?.name
          : item.owner?.name
        : item.name;

    let receiver =
      item.type === "individual"
        ? authUser.id === item.owner?._id
          ? item.members[0]
          : item.owner
        : "";
        // clear_unread_count(currRoomRef.current.id);
        // store.dispatch({
        //   type: ACTION_TYPES_ROOMS.ROOM_UPDATE_COUNT_ONLY_RESET,
        //   payload: currRoomRef.current,
        // });
    setContact(receiver);
    setRoomname(room_name);
    setcurrRoom(item);
    currRoomRef.current = item
    setischatboxactive(true);

    clear_unread_count(item.id);
    
    let rom = item;
    rom.unread_count = 0;
    store.dispatch({
      type: ACTION_TYPES_ROOMS.ROOM_UPDATE_COUNT,
      payload: rom,
    });
  };

  useEffect(() => {
    currRoomRef.current = currRoom; // Keep ref in sync with state
  }, [currRoom]);

  const getInitials = text => {
    if (!text) {
      return "NA";
    }
    let words = text?.split(" ").filter(word => word); // Split and remove extra spaces
    let initials =
      words.length > 2
        ? words.slice(0, 2).map(word => word[0]) // Pick first two words' initials
        : words.map(word => word[0]); // Pick only one if one word

    return initials.join("").toUpperCase();
  };
  useEffect(() => {
    console.log("props.users", props.users);
    setUsers(props.users);
  }, [props.users]);

  const createNewRoom = isgroup => {
    props.fetchUsersPagination(1, 200);
    setIsgroup(isgroup);
    setIscreate(true);
  };

  const handleUserSearch = async searchText => {
    console.log(searchText);
    props.fetchUsersPagination(1, 200, searchText);
  };
  const debounced = useCallback(debounce(handleUserSearch, 500), []);

  const contactclickbottomsheet = item => {
    let member = item._id;
    console.log(item);
    if (isgroup) {
      setGroupmembers(prevMembers => {
        if (prevMembers.includes(member)) {
          // If the member is already in the array, remove it
          return prevMembers.filter(m => m !== member);
        } else {
          // If the member is not in the array, add it
          return [...prevMembers, member];
        }
      });
      //creategroup();
    } else {
      createchatroom(item);
    }
  };

  const createchatroom = item => {
    // console.log(item, authUser);

    let room = {};

    room.name = item.name;
    room.owner = authUser.id;
    room.members = [item.id];
    room.type = "individual";
    const onSuccess = item => {
      console.log("createchatroom", item);
      setGroupmembers([]);
      onRoomClick(item);
      setIsgroup(null);
      setIscreate(false);
    };
    props.createRoom(room, onSuccess);
  };

  const creategroup = () => {
    // console.log(item, authUser)

    let room = {};

    room.name = groupname;
    room.owner = authUser.id;
    room.members = groupmembers;
    room.type = "group";
    const onSuccess = item => {
      console.log("createchatroom", item);
      setGroupmembers([]);
      setgroupname("");
      onRoomClick(item);
      setIsgroup(null);
      setIscreate(false);
    };
    props.createRoom(room, onSuccess);
  };

  const handleFavorite1 = async item => {
   setcurrRoom({...currRoom, favorite: !item.favorite});
 
    await API.room().update(item.id, {favorite: [...item.favorite, authUser.id], updatedBy: ''})
    .then(res => {
      console.log("res", res);
    })
  }
  const handleFavorite = async (item) => {
    const currentFavorites = Array.isArray(item.favorite) ? item.favorite : [];
    const isFavorited = currentFavorites.includes(authUser.id);
  
    const updatedFavorites = isFavorited
      ? currentFavorites.filter(id => id !== authUser.id) // Remove authUser.id
      : [...currentFavorites, authUser.id]; // Add authUser.id
  
    setcurrRoom({ ...currRoom, favorite: updatedFavorites });
    store.dispatch({
      type: ACTION_TYPES_ROOMS.ROOM_UPDATE_NO,
      payload: { ...item, favorite: updatedFavorites },
    });
  
    await API.room().update(item.id, { 
      favorite: updatedFavorites, 
      updatedBy: ""
    })
    .then(res => {
      console.log("Favorite updated", res);
    })
    .catch(err => {
      console.error("Error updating favorite", err);
    });
  };

  return (
    <section className="message-area">
      <div className="row">
        <div className="col-12">
          <div className={"chat-area " + (true ? "chatboxactive" : "")}>
            {/* chatlist */}
            <div className="chatlist">
              <div className="modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="chat-header my-1">
                    <div className="msg-search mb-3">
                      <div className="fw-bold h4">Engage Chat</div>
                      <ul className="moreoption p-0 m-0">
                        <li className="navbar nav-item dropdown">
                          <a
                            className="nav-link dropdown-toggle ms-2 me-2 d-flex"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <AddCircleOutline color="action" style={{fontSize:21, marginTop:1.5}} /> &nbsp;New Chat
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              {/* <FormDialogAddCustomer
                                create={props.createCustomer}
                                currentTenantnumber={props.currentTenantnumber}
                                createRoom={createRoom}
                              /> */}
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={() => createNewRoom(false)}
                              >
                                New Chat
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={() => createNewRoom(true)}
                              >
                                New Group
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div className="msg-search">
                      <input
                        type="search"
                        className="form-control border-radius-40"
                        id="inlineFormInputGroup"
                        placeholder="Search.."
                        value={searchtext}
                        onChange={e => setsearchtext(e.target.value)}
                        autoComplete="off"

                      />
                    </div>
                  </div>
                  <div
                    className="modal-body mt-2 overflow-hidden"
                    style={{ overflowY: "none" }}
                  >

                    {/* chat-list */}
                    <div className="chat-lists">
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="Open"
                          role="tabpanel"
                          aria-labelledby="Open-tab"
                        >
                          {/* chat-list */}
                          <div className="pb-2">
                          <Chip onClick={()=>setFilter('all')} variant={filter==='all'?'solid':"outlined"} color={filter==='all'?'primary':""} size="small" label="All" className="me-2"  />
                          <Chip onClick={()=>setFilter('unread')} variant={filter==='unread'?'solid':"outlined"} color={filter==='unread'?'primary':""} size="small" label="Unread" className="me-2" />
                          <Chip onClick={()=>setFilter('groups')} variant={filter==='groups'?'solid':"outlined"} color={filter==='groups'?'primary':""}  size="small" label="Groups" className="me-2" />
                          <Chip onClick={()=>setFilter('favorites')} variant={filter==='favorites'?'solid':"outlined"} color={filter==='favorites'?'primary':""}  size="small" label="Favorites" className="me-2" />

                          </div>
                          <div className="chat-list">
                            <div className="contactlist">
                              {chatrooms.length > 0 && (
                                <Virtuoso
                                  style={{ height: "83vh" }}
                                  data={chatrooms}
                                  endReached={handleChangePage}
                                  itemContent={(index, data) => (
                                    <a
                                      className={
                                        "d-flex align-items-center px-2 py-2.5 " +
                                        (currRoom.id === data.id
                                          ? "bg-lightgray"
                                          : "")
                                      }
                                      onClick={() => onRoomClick(data)}
                                      key={index}
                                    >
                                      {/* Avatar */}
                                      <div className="flex-shrink-0">
                                        {onlineusers?.includes(
                                          data.type === "individual"
                                            ? authUser?.id === data?.owner?._id
                                              ? data?.members[0]?._id
                                              : data?.owner?._id
                                            : data?._id,
                                        ) ? (
                                          <FiberManualRecord
                                            style={{
                                              fontSize: 14,
                                              color: "#50C878",
                                              position: "absolute",
                                              zIndex: 1,
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )}

                                        <Avatar style={{ backgroundColor: getRandomColor() }} >
                                          {getInitials(
                                            data.type === "individual"
                                              ? authUser.id === data.owner?._id
                                                ? data.members[0]?.name
                                                : data.owner?.name
                                              : data.name,
                                          ) || "NA"}
                                        </Avatar>
                                      </div>

                                      {/* Chat Details */}
                                      <div className="flex-grow-1 ms-2">
                                        {/* Badges at the Top Right */}
                                        <div className="d-flex justify-content-between align-items-center mb-1">
                                          <span className="fw-semibold opacity-75 text-black text-truncate">
                                            {data.type === "individual"
                                              ? authUser.id === data.owner?._id
                                                ? data.members[0]?.name
                                                : data.owner?.name
                                              : data.name} 
                                          </span>
                                          {/* {data.id} */}
                                          {data?.unread_count > 0 && (
                                            <div className="d-flex">
                                              <span
                                                className="badgeimg ms-1"
                                                style={{ fontSize: 9 }}
                                              >
                                                {data?.unread_count}
                                              </span>
                                            </div>
                                          )}
                                        </div>

                                        {/* Last Message (Truncated) */}

                                        {/* Date at the Bottom Right (Reduced Size) */}
                                        <div className="d-flex justify-content-between">
                                          {/* Last Message (Left-Aligned) */}
                                          <small
                                            className="text-muted text-truncate"
                                            style={{
                                              maxWidth: "200px",
                                              flexGrow: 1,
                                            }} // Ensures it takes available space
                                          >
                                            {data?.lastmsg?.replace(
                                              /<[^>]*>/g,
                                              " ",
                                            )}
                                            
                                          </small>

                                          {/* Date (Right-Aligned, Smaller Font) */}
                                          <small
                                            className="text-muted ms-2"
                                            style={{
                                              fontSize: "10px",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            {moment(data?.updatedAt).format(
                                              "D MMM, h:mm a",
                                            )}
                                          </small>
                                        </div>
                                      </div>
                                    </a>
                                  )}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* chatbox */}
            <div className="chatbox">
              {currRoom?._id ? (
                <div className="modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="msg-head">
                      <div className="row">
                        <div className="col-10">
                          <div className="d-flex align-items-center">
                            {/* <span
                              className="chat-icon d-md-block d-none"
                              onClick={() => setischatboxactive(false)}
                            >
                              <img
                                className="img-fluid"
                                src="https://mehedihtml.com/chatbox/assets/img/arroleftt.svg"
                                alt="image title"
                              />
                            </span> */}
                            <div className="flex-shrink-0">
                              <Avatar>{getInitials(roomname)}</Avatar>
                            </div>
                            <div className="flex-grow-1 ms-2">
                              <div className="text-capitalize fw-semibold h6 mb-0">
                                {roomname}
                              </div>
                              {onlineusers?.includes(
                                currRoom.type === "individual"
                                  ? authUser?.id === currRoom?.owner?._id
                                    ? currRoom?.members[0]?._id
                                    : currRoom?.owner?._id
                                  : currRoom?._id,
                              )
                                ? "Online"
                                : ""}
                            </div>
                          </div>
                        </div>
                        
                          <div className="col-2 mt-1">
                            <ul className="moreoption">
                              <li className="navbar nav-item dropdown me-2">
                                <a onClick={()=>handleFavorite(currRoom)}>{currRoom?.favorite?.includes?.(authUser.id)?<Star/>:<StarBorder /> }</a>
                                </li>
                              {/* <li className="navbar nav-item dropdown">
                                <a
                                  className="nav-link dropdown-toggle"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <MoreVert />
                                </a>
                                <ul className="dropdown-menu">
                                 
                                  <li>
                                                                <a className="dropdown-item" >
                                                                    Edit Label
                                                                </a>
                                                            </li> 
                                   <li>
                                                                <hr className="dropdown-divider" />
                                                            </li>
                                                            <li>
                                                                <a className="dropdown-item">
                                                                    Something else here
                                                                </a>
                                                            </li>
                                </ul>
                              </li> */}
                            </ul>
                          </div>
                        
                      </div>
                    </div>
                    <Chatbox contact={contact} currRoom={currRoom} navigatetoroom={onRoomClick} chatrooms={chatrooms} fetchRoomsPagination= {props.fetchRoomsPagination}/>
                  </div>
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center vh-100">
                  <img src={logo} alt="logo" className="mb-2" />{" "}
                  {/* Adds spacing below image */}
                  {/* <p className="text-center h6">Choose contact to start chat</p> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={isCreate}
        onClose={() => setIscreate(!isCreate)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">
          {" "}
          {isgroup ? "New group" : "New chat"}
        </DialogTitle>
        <DialogContent>
          <div className="contactlist">
          {isgroup && <input
                            placeholder="Group Name"
                                className="form-control mb-2 mt-n2"
                                onChange={e => setgroupname(e.target.value)}
                            style={{
                              borderWidth: 1,
                              borderRadius: 10,
                              borderColor: '#ccc',
                              paddingLeft: 10,
                              paddingRight: 10,
                              height: 40,
                            }}
                          />}

            <input
              placeholder="Search"
              className="form-control mb-2 mt-n2"
              onChange={e => debounced(e.target.value)}
              style={{
                borderWidth: 1,
                borderRadius: 10,
                borderColor: "#ccc",
                paddingLeft: 10,
                height: 40,
              }}
            />
            <TableVirtuoso
              style={{ height: 400 }}
              data={users?.sort(
                (a, b) =>
                  onlineusers?.includes(b?._id) -
                  onlineusers?.includes(a?._id),
              )}
              components={{
                Table: props => (
                  <table className="table">{props.children}</table>
                ),
                TableBody: React.forwardRef((props, ref) => (
                  <tbody {...props} ref={ref} />
                )),
                TableRow: ({ item, ...props }) => (
                  <tr
                    {...props}
                    onClick={() => contactclickbottomsheet(item)}
                    style={{ cursor: "pointer" }}
                  >
                    <td>
                    <div className="flex-shrink-0">
                                        {onlineusers?.includes(item._id) ? (
                                          <FiberManualRecord
                                            style={{
                                              fontSize: 10,
                                              color: "#50C878",
                                              position: "absolute",
                                              zIndex: 1,
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )}

                                        <Avatar style={{ backgroundColor: getRandomColor(), width: 30, height: 30, }} sizes="small">
                                          {getInitials(item.firstname) || "NA"}
                                        </Avatar>
                                      </div>
                    </td>
                    <td className="border-bottom py-2 w-50">
                     {item.firstname} {item.lastname}
                    </td>
                    <td className="border-bottom py-2 w-50">
                      {item.division}
                    </td>
                    <td className="border-bottom py-2 w-50">{item.email}</td>
                    {isgroup && (
                      <td className="border-bottom py-2 w-50 text-center">
                        {groupmembers.includes(item._id) && (
        <Check style={{fontSize:14}} />
      )} 
                      </td>
                    )}
                  </tr>
                ),
              }}
              itemContent={() => null} // Remove itemContent, as we're defining TableRow separately
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIscreate(!isCreate)} color="primary">
            Cancel
          </Button>
          {isgroup && (
         
            <Button
          
            disabled={
              !(groupname.length > 0 && groupmembers.length > 0)
            }
            onClick={creategroup}>
          Create Group
          </Button>
          )}
        </DialogActions>
      </Dialog>
      <audio ref={audioPlayer} src={NotificationSound} />
    </section>
  );
};

const mapStateToProps = state => ({
  rooms: state.room.rooms,
  metaRoom: state.room.metaRoom,
  users: state.user.users,
  onlineusers: state.user.onlineusers,
  metaUser: state.user.metaUser,
});

const mapActionToProps = {
  fetchRoomsPagination: actions.Pagination,
  searchRooms: actions.findPaginationWithSearch,
  fetchUsersPagination: users.Pagination,
  createRoom: actions.create,
  getUnreadCount: actions.getUnreadCount,
  updateRoom: actions.update,
};

export default connect(mapStateToProps, mapActionToProps)(Inbox);
