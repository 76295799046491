import {ACTION_TYPES} from '../actions/room';

const initialState = {
  rooms: [],
  metaRoom: {},
  vcroom: {},
  unreadCount: 0
};

export const room = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.ROOM_FETCH_ALL:
      return {
        ...state,
        rooms: [...action.payload],
      };
      case ACTION_TYPES.ROOM_FETCH_UNREAD_COUNT:
      return {
        ...state,
        unreadCount: action.payload,
      };
    case ACTION_TYPES.ROOM_CREATE:
      //console.log('ROOM_CREATE',action.payload, state.rooms)
      let oldrooms;
      if (action.payload.exist) {
        oldrooms = state.rooms.filter(x => x.id !== action.payload.id);
      } else {
        oldrooms = state.rooms;
      }
      return {
        ...state,
        rooms: [action.payload, ...oldrooms],
      };
    case ACTION_TYPES.ROOM_UPDATE:
       console.log('action.payload',action.payload)
      return {
        ...state,
        rooms: [
          action.payload, // Append the updated room at the top
          ...state.rooms.filter(x => x.id !== action.payload.id), // Filter out the existing room if it matches
        ],
      };
      case ACTION_TYPES.ROOM_UPDATE_NO:
                return {
                    ...state,
                    rooms: state.rooms.map(x => x.id === action.payload.id ? action.payload : x)
                }
    case ACTION_TYPES.ROOM_DELETE:
      return {
        ...state,
        rooms: state.rooms.filter(x => x.id !== action.payload),
      };
    case ACTION_TYPES.ROOM_PAGINATION:
      return {
        ...state,
        rooms:
          action.payload.meta.page === 1
            ? action.payload.rooms
            : [...state.rooms, ...action.payload.rooms],
        metaRoom: action.payload.meta,
      };
    case ACTION_TYPES.VCROOM_UPDATE:
      return {
        ...state,
        vcroom: action.payload,
      };
      case ACTION_TYPES.ROOM_UPDATE_COUNT:
        return {
          ...state,
          rooms: state.rooms.map(room =>
            room?.id === action?.payload?.id
              ? { ...room, unreadCount: action.payload.unreadCount } // Merge changes
              : room
          )
        };

  case ACTION_TYPES.ROOM_UPDATE_COUNT_ONLY_RESET:
    return {
      ...state,
      rooms: state.rooms.map(room =>
        room.id === action.payload.id ? action.payload : {...room, unread_count: 0}
      ), // Replace the matching room while keeping the order unchanged
    };
    default:
      return state;
  }
};
