import {ACTION_TYPES} from '../actions/user';

const initialState = {
  users: [],
  metaUser: {},
  cart: {},
  cartArray: [],
  authuser: {},
  authuser_m: {},
  onlineusers: [],
};

export const user = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.USER_FETCH_LOGIN:
      return {
        ...state,
        authuser: action.payload,
      };

    case ACTION_TYPES.USER_FETCH_LOGIN_M:
      return {
        ...state,
        authuser_m: action.payload,
      };
    case ACTION_TYPES.USER_FETCH_ALL:
      return {
        ...state,
        users: [...action.payload],
      };
    case ACTION_TYPES.USER_ONLINE:
      return {
        ...state,
        onlineusers: [...action.payload],
      };
    case ACTION_TYPES.USER_CREATE:
      return {
        ...state,
        users: [...state.users, action.payload],
      };
    case ACTION_TYPES.USER_UPDATE:
      return {
        ...state,
        users: state.users.map(x =>
          x.id === action.payload.id ? action.payload : x,
        ),
      };
    case ACTION_TYPES.USER_CART_UPDATE:
      return {
        ...state,
        cart: action.payload.cart,
        cartArray: [...action.payload.cartArray],
      };
    case ACTION_TYPES.USER_DELETE:
      return {
        ...state,
        users: state.users.filter(x => x.id !== action.payload),
      };
    case ACTION_TYPES.USER_PAGINATION:
      return {
        ...state,
        //users: [...action.payload.users],
        users:
          action.payload.meta.page === 1
            ? action.payload.users
            : [...state.users, ...action.payload.users],

        //users: [...state.users,...action.payload.users],
        metaUser: action.payload.meta,
      };
    default:
      return state;
  }
};
